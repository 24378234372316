import React, { useState, useRef, useEffect } from "react";
import { useTheme } from "../../ThemeProvider";
import "./mainHeader.css";
import logoInner from "../../assets/svg/logo-inner.svg";
import writtenLogo from "../../assets/svg/logo-written.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faBars,
  faMoon,
  faSun,
} from "@fortawesome/free-solid-svg-icons";

function Header() {
  const { theme, toggleTheme } = useTheme();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const mobileMenuRef = useRef(null);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 950) {
        setIsMobileMenuOpen(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        event.target.classList.contains("header-burger-menu")
      ) {
        setIsMobileMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const toggleNightMode = () => {
    toggleTheme();
  };

  return (
    <div className={`header ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="header-container">
        {/* LOGO */}
        <div className="header-logo">
          <a href="/" target="" rel="noreferrer">
            <img
              src={logoInner}
              className="header-innerlogo"
              alt="Inner Logo"
            />
            <img
              src={writtenLogo}
              className="header-writtenlogo"
              alt="Written Logo"
            />
          </a>
        </div>
        {/* NAV */}
        <div className="header-navigation">
          <NavItem
            text="Product"
            subMenu={<ProductSubMenu />}
            showArrow={true}
          />
          <div className="header-headerItemDivider"></div>
          <NavItem
            text="Project"
            subMenu={<ProjectSubMenu />}
            showArrow={true}
          />
          <div className="header-headerItemDivider"></div>
          <NavItem
            text="Usecases"
            subMenu={<UsecasesSubMenu />}
            showArrow={false}
            link="/usecases"
          />
          <div className="header-headerItemDivider"></div>
          <NavItem
            text="Resources"
            subMenu={<ResourcesSubMenu />}
            showArrow={true}
          />
          <div className="header-headerItemDivider"></div>
          <NavItem
            text="Token"
            subMenu={<TokenSubMenu />}
            logo={writtenLogo}
            showArrow={false}
            link="/token"
          />
          <div className="header-headerItemDivider"></div>
          <NavItem
            text="Blog"
            logo={writtenLogo}
            showArrow={false}
            link="https://blog.3vo.me"
          />
        </div>
        {/* ACTION BUTTON */}
        <div className="header-action-button-container">
          <FontAwesomeIcon
            icon={theme === "light" ? faMoon : faSun}
            className="header-theme-switcher"
            onClick={toggleNightMode}
          />
          <a
            href="https://play.google.com/store/apps/details?id=me.threevo.client"
            target="_blank"
            rel="noreferrer"
          >
            <div className="header-action-button">Get App</div>
          </a>
          <a href="/quests" target="" rel="noreferrer">
            <div className="header-action-button-quests">
              <div className="shake-text">Quests</div>
            </div>
          </a>
        </div>
        {/* BURGER MENU */}
        <div className="header-burger-menu">
          {isMobileMenuOpen ? (
            <>
              <FontAwesomeIcon
                icon={theme === "light" ? faMoon : faSun}
                className="header-theme-switcher"
                onClick={toggleNightMode}
              />
              <FontAwesomeIcon
                icon={faXmark}
                className="header-burger-menu-icon"
                onClick={toggleMobileMenu}
              />
            </>
          ) : (
            <FontAwesomeIcon
              icon={faBars}
              className="header-burger-menu-icon"
              id="close-button"
              onClick={toggleMobileMenu}
            />
          )}
        </div>
        {/* MOBILE NAV MENU */}
        {isMobileMenuOpen && (
          <div ref={mobileMenuRef} className="header-mobile-menu">
            <div className="header-mobile-logo">
              <a href="/" target="" rel="noreferrer">
                <img
                  src={logoInner}
                  className="header-innerlogo"
                  alt="Inner Logo"
                />
              </a>
            </div>
            <NavItem text="Product" subMenu={<ProductSubMenu />} />
            <NavItem text="Project" subMenu={<ProjectSubMenu />} />
            <NavItem
              text="Usecases"
              showArrow={false}
              link="/usecases"
              subMenu={<UsecasesSubMenu />}
            />
            <NavItem text="Resources" subMenu={<ResourcesSubMenu />} />
            <NavItem
              text="Token"
              showArrow={false}
              link="/token"
              subMenu={<TokenSubMenu />}
            />
            <NavItem text="Blog" showArrow={false} link="https://blog.3vo.me" />
            <div className="header-mobile-action">
              <a
                href="https://play.google.com/store/apps/details?id=me.threevo.client"
                target="_blank"
                rel="noreferrer"
              >
                <div className="header-action-button">Get App</div>
              </a>
              <a href="/quests" target="" rel="noreferrer">
                <div className="header-action-button-quests">
                  <div className="shake-text">Quests</div>
                </div>
              </a>
            </div>
            {/* <div className="header-action-button-container">
              <a href="/app" target="" rel="noreferrer">
                <div className="header-action-button">Get App</div>
              </a>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}

function NavItem({ text, subMenu, showArrow, link }) {
  const [state, setState] = useState("normal");
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const handleMouseEnter = () => {
    setState("hover");
  };

  const handleMouseDown = () => {
    if (!link) {
      setState("pressed");
      setIsOpen(true);
    } else {
      window.location.href = link;
    }
  };

  const handleMouseLeave = () => {
    setState("normal");
  };

  const handleOpen = () => {
    setIsOpen(isOpen);
  };

  return (
    <div
      className={`header-navitem ${state} ${isOpen ? "open" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onClick={handleOpen}
      ref={ref}
    >
      <div className="header-headerItem">
        <div className="header-headerItem-text">{text}</div>
        {showArrow && (
          <svg
            className={`header-headerItem-arrow ${state}`}
            viewBox="0 0 12 8"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.7311 0.5H2.05538C1.00382 0.5 0.414336 1.55576 1.06403 2.27547L5.40188 7.08099C5.90596 7.63967 6.87911 7.63967 7.38458 7.08099L11.7224 2.27407C12.3721 1.55576 11.7826 0.5 10.7311 0.5Z" />
          </svg>
        )}
      </div>
      {isOpen && !link && (
        <div className="header-submenu" onClick={(e) => e.stopPropagation()}>
          {subMenu}
        </div>
      )}
    </div>
  );
}

function ProductSubMenu() {
  const submenuItems = [
    {
      name: "Hub",
      logo: logoInner,
      title: "3VO Hub",
      description: "Home of the 3VO Ecosystem",
      to: "/hub",
    },
    {
      name: "App",
      logo: logoInner,
      title: "3VO App",
      description:
        "One app for social and crypto. With built-in wallet for digital value",
      to: "/app",
    },
    // {
    //   name: "Wallet",
    //   logo: logoInner,
    //   title: "3VO Wallet",
    //   description: "DeFi Wallet",
    //   to: "/wallet",
    // },
    // {
    //   name: "Dapps",
    //   logo: logoInner,
    //   title: "Dapps",
    //   description: "Dapps",
    //   to: "/dapps",
    // },
  ];

  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemHover = (itemName) => {
    setSelectedItem(itemName);
  };

  const handleSubMenuLeave = () => {
    // Do nothing to keep the selected item when leaving the submenu
  };

  return (
    <div className="header-submenu" onMouseLeave={handleSubMenuLeave}>
      <div className="header-submenu-links">
        {submenuItems.map((item) => (
          <a href={item.to} target="" rel="noreferrer">
            <div
              key={item.name}
              className="header-submenu-button"
              onMouseEnter={() => handleItemHover(item.name)}
            >
              {item.name}
            </div>
          </a>
        ))}
      </div>
      <div className="header-submenu-divider"></div>
      <div className="header-submenu-description">
        {selectedItem ? (
          submenuItems.map((item) =>
            item.name === selectedItem ? (
              <div
                key={item.name}
                className="header-submenu-description-content"
              >
                <div className="header-submenu-description-logo">
                  <img src={item.logo} alt={item.name} />
                </div>
                <div className="header-submenu-description-title">
                  {item.title}
                </div>
                <div className="header-submenu-description-text">
                  {item.description}
                </div>
              </div>
            ) : null
          )
        ) : (
          <div className="header-submenu-description-placeholder">
            <div className="header-submenu-description-logo">
              <img src={logoInner} alt="logo" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function ProjectSubMenu() {
  return (
    <div>
      <div className="header-submenu-links">
        <a
          href="/project#about"
          target=""
          rel="noreferrer"
          className="header-submenu-button"
        >
          About
        </a>
        <a
          href="/project#team"
          target=""
          rel="noreferrer"
          className="header-submenu-button"
        >
          Team
        </a>
        <a
          href="/project#advisors"
          target=""
          rel="noreferrer"
          className="header-submenu-button"
        >
          Advisors
        </a>
        <a
          href="/project#roadmap"
          target=""
          rel="noreferrer"
          className="header-submenu-button"
        >
          Roadmap
        </a>
      </div>
    </div>
  );
}

function UsecasesSubMenu() {
  return <div></div>;
}

function ResourcesSubMenu() {
  return (
    <div>
      <div className="header-submenu-links">
        <a href="https://docs.3vo.me" target="_blank" rel="noreferrer">
          <div className="header-submenu-button">Documentation</div>
        </a>
        {/* <a
          href="https://docsend.com/view/wjc59cp7f44rzezm"
          target="_blank"
          rel="noreferrer"
        >
          <div className="header-submenu-button">Tokenomics</div>
        </a> */}
        <a
          href="https://docsend.com/view/2tgw63cdhw64uqu5"
          target="_blank"
          rel="noreferrer"
        >
          <div className="header-submenu-button">Pitch Deck</div>
        </a>
      </div>
    </div>
  );
}

function TokenSubMenu() {
  return <div></div>;
}

export default Header;
