import React, { useEffect, useState } from "react";
import { useTheme } from "../ThemeProvider";
import { useParams, useLocation } from "react-router-dom";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import image from "../assets/images/visit.webp";

function MysteryBox() {
  const { theme } = useTheme();
  const location = useLocation();
  const { boxId, userId } = useParams();
  const [visited, setVisited] = useState(false);
  const db = getFirestore();

  document.title = "3VO | Mystery Box";

  useEffect(() => {
    if (visited) {
      const queryParams = new URLSearchParams(location.search);
      const targetUrl = queryParams.get("target");

      if (targetUrl) {
        window.location.href = targetUrl;
      }
    }
  }, [visited, location]);

  const handleVisitClick = async () => {
    try {
      const visitDocRef = doc(db, "visits", `${boxId}_${userId}`);
      const visitDoc = await getDoc(visitDocRef);

      if (!visitDoc.exists()) {
        await setDoc(visitDocRef, {
          userId: userId,
          boxId: boxId,
          timestamp: new Date().toISOString(),
          visited: true,
        });
        setVisited(true);
      } else {
        setVisited(true);
      }
    } catch (error) {
      console.error("Error storing visit data in Firestore:", error);
    }
  };

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <div className="redirect-page">
        <img className="redirect-image" src={image} alt="Visit" />
        <div className="redirect-button">
          <button
            className="callToAction-action-button"
            onClick={handleVisitClick}
          >
            Visit now
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MysteryBox;
