import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import App from "./App";
import ContactUs from "./pages/contactus";
import RequestForm from "./pages/requestForms";
import Defi from "./pages/defi";
import Presale from "./pages/presale";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsOfUse from "./pages/termOfUse";
import Freedom from "./pages/freedom";
import Social from "./pages/social";
import Dapps from "./pages/dapps";
import Usecases from "./pages/usecases";
import TuckerReports from "./pages/tuckerReports";
import TastyPizza from "./pages/tastyPizza";
import FashionGabriela from "./pages/fashionGabriela";
import TheOnlyMaya from "./pages/theOnlyMaya";
import Project from "./pages/project";
import Token from "./pages/token";
import RequestUsername from "./pages/requestUsername";
import Earn from "./pages/competitions";
import Tutorial from "./pages/tutorial";
import Quests from "./pages/quest";
import PlayerOne from "./pages/playerOne";
import FitnessFlex from "./pages/fitnessFlex";
import MysteryBox from "./pages/mysteryBox";

const ExternalRedirect = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null; // Since we're redirecting, no need to render anything
};

const RoutesFile = () => {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      {/* Main Subpages */}
      <Route path="/wallet" element={<Defi />} />
      <Route path="/hub" element={<Freedom />} />
      <Route path="/app" element={<Social />} />
      <Route path="/tutorial" element={<Tutorial />} />
      <Route path="/dapps" element={<Dapps />} />
      <Route path="/usecases" element={<Usecases />} />
      <Route path="/project" element={<Project />} />
      <Route path="/token" element={<Token />} />
      {/* Usecases */}
      <Route path="/usecases/reporter" element={<TuckerReports />} />
      <Route path="/usecases/restaurant" element={<TastyPizza />} />
      <Route path="/usecases/fashion" element={<FashionGabriela />} />
      <Route path="/usecases/creator" element={<TheOnlyMaya />} />
      <Route path="/usecases/streamer" element={<PlayerOne />} />
      <Route path="/usecases/gym" element={<FitnessFlex />} />
      {/* Footer Subpages */}
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/forms" element={<RequestForm />} />
      <Route
        path="/presale"
        element={
          <ExternalRedirect url="https://3vo-team.odoo.com/survey/start/d58061ba-95d7-4c92-857f-e0a589b8e1e5" />
        }
      />
      <Route
        path="/whitelist"
        element={
          <ExternalRedirect url="https://3vo-team.odoo.com/survey/start/d58061ba-95d7-4c92-857f-e0a589b8e1e5" />
        }
      />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfUse />} />
      <Route path="/username" element={<RequestUsername />} />
      <Route path="/earn" element={<Earn />} />{" "}
      <Route path="/quests" element={<Quests />} />
      <Route path="/mysterybox/:boxId/:userId" element={<MysteryBox />} />
    </Routes>
  );
};

export default RoutesFile;
